import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ArrowLeft } from 'modules/v2/common/components/SvgIcon/ArrowLeft/index';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getOrderedHistoryRoute } from 'modules/v2/routes/navigation';
import { compose } from 'redux';
import { Box, Button, Radio } from 'modules/v2/common/AtomicDesign/atoms';
import { Dropdown } from 'modules/v2/common/AtomicDesign/molecules';
import { CreditCardIcon } from 'modules/common/components/SvgIcon';
import ShippingIcon from 'modules/common/components/SvgIcon/ShippingIcon';
import { AddCircleIcon } from 'modules/v2/common/components/SvgIcon';
import InfoCircle from 'modules/common/components/SvgIcon/InfoCircle';
import { notification } from 'modules/common/utils';
import { ProcessingModal, AddCardModal, ChangeDefaultPaymentModal } from 'modules/v2/common/AtomicDesign/organisms';
import ConfirmationModal from 'modules/v2/common/AtomicDesign/organisms/ConfirmationModal';
import { AddressFormView } from 'modules/v2/common/views/AddressView/views';
import ShippingAddressSelectNew from 'modules/v2/draft/pages/Checkout/ShippingAddressSelectNew';
import ShippingNewAddress from 'modules/v2/draft/pages/Checkout/ShippingNewAddress';
import { withAddress } from 'modules/v2/containers';
import {
  fetchUserAddresses,
  getDefaultPaymentProfile, getPaymentProfilesList
} from 'modules/api';
import ObjectSet from 'modules/v2/common/utils/set';
import { STATUS_SUCCESS } from 'store/status';
import { CreditCardImages } from 'modules/v2/utils';
import { withPublish } from 'modules/v2/draft/containers';
import PaymentCardsSkeleton from 'modules/v2/common/AtomicDesign/atoms/PaymentCardsSkeleton';
import { customLoadingModalTheme } from './theme';

const GuideCheckout = ({
  countries,
  states,
  addUserAddresses,
  order,
  clearPublish,
}) => {
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [ guideQuantity, setGuideQuantity] = useState(8);
  const [ selectedQtyOption, setSelectedQtyOption] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [availableShippingAddresses, setAvailableShippingAddresses] = useState([]);
  const history = useHistory();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isReadyToCheckOrder, setIsReadyToCheckOrder] = useState(false);
  const [availablePaymentOptions, setAvailablePaymentOptions] = useState(new ObjectSet());
  const [defaultPaymentOption, setDefaultPaymentOption] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [defaultCardModal, setDefaultCardModal] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  const {
    isLoading: loadingGetPaymentProfilesList,
    isRefetching: refetchingGetPaymentProfilesList,
  } = useQuery('getPaymentProfilesList', getPaymentProfilesList, {
    onSuccess: (data) => {
      const set = new ObjectSet();
      data.forEach((item) => set.add(item));
      setAvailablePaymentOptions(set);
    },
  });

  const {
    isLoading: defaultProfileLoading,
    isRefetching: defaultProfileRefetching,
  } = useQuery('getDefaultPaymentProfile', getDefaultPaymentProfile, {
    onSuccess: (data) => {
      setDefaultPaymentOption(data);
    },
  });

  const productsOptions = [
    {
      id: 1,
      label: '20 copies',
      qty: 20,
      price: '$99',
    },
    {
      id: 2,
      label: '40 copies',
      price: '$199',
      qty: 40,
    },
  ];

  useEffect(() => {
    setSelectedQtyOption(productsOptions[0]);
  }, []);

  const selectedOrderContext = {
    guideId: 1234,
    draftId: '12345',
    title: 'A guide to selling your home',
    thumbnail: {
      url: 'https://afy-ui-stg.s3.us-east-1.amazonaws.com/images/seller/seller-guide-blue.png',
    },
    html: '<p>Some HTML content</p>',
    orderId: '0000111',
    qty: 8,
    shippingAddress: {
      addressLine1: 'addressLine1',
      addressLine2: 'addressLine2',
      city: 'city',
      state: 'Florida',
      country: 'US',
      pincode: '32233',
    },
    createdAt: 'Nov 4, 2024, 3:26 PM',
  };


  const addressFormRef = useRef();

  const {
    refetch: fetchUserAddressesRefetch,
    isRefetching: isRefetchingUserAddress,
    isLoading: isLoadingUserAddress,
  } = useQuery('fetchUserAddresses', fetchUserAddresses, {
    onSuccess: (res) => {
      const {
        data: { data },
      } = res;

      const { shippingAddress } = data;
      setAvailableShippingAddresses(shippingAddress);

      const defaultSelectedAddress = shippingAddress.find((address) => address.isDefault === true);
      if (defaultSelectedAddress) {
        setSelectedAddress(defaultSelectedAddress);
      } else if (shippingAddress.length === 1) {
        setSelectedAddress(shippingAddress[0]);
      } else {
        setSelectedAddress(null);
      }
    },
    onError: (error) => {
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Network Error',
        autoClose: false,
      });
    },
  });

  const handleAddressFormAndSubmit = async () => {
    if (addressFormRef.current) {
      await addressFormRef.current.submit();
    }
    setIsReadyToCheckOrder(true);
  };

  const handleAddShippingAddress = async (fields) => {
    await addUserAddresses({ ...fields, isDefault: true });
    await fetchUserAddressesRefetch();
    setIsReadyToCheckOrder(true);
  };


  function styleMask(item) {
    return (
      item?.masked_card_number.slice(0, -4).replace(/./g, '*') + item?.masked_card_number.slice(-4)
    );
  }

  const isDefaultPayment = (itemId) => {
    return itemId === defaultPaymentOption?.id;
  };

  const handlePreflightCheck = async () => {
    try {
      setShowLoadingModal(true);
    } catch (error) {
      setShowLoadingModal(false);
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Error during pre-flight check',
        autoClose: false,
      });
    }
  };

  const handleShowShippingModal = () => {
    setShowShippingModal(true);
  };

  const handleCheckoutSubmit = async () => {
    try {
      if (addressFormRef.current) {
        const validation = await addressFormRef?.current?.validate();
        if (Object.keys(validation).length > 0) {
          return;
        }
      } else if (!selectedAddress) {
        notification.warning({
          description: 'Please select a shipping address',
          autoClose: false,
        });
        return;
      }

      await handleAddressFormAndSubmit();
    } catch (error) {
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Network Error',
        autoClose: false,
      });
    }
  };


  const handleConfirmation = () => {
    history.push(getOrderedHistoryRoute());
  };

  const handleAddNewPaymentMethod = () => {
    setShowAddCardModal(true);
  };

  const thumbnailUrl = selectedOrderContext?.thumbnail?.url;
  const guideTitle = selectedOrderContext?.title;

  const GuideThumbnail = ({ src, alt, className }) => (
    <div
      className={`h-32 md:h-48 overflow-hidden rounded-lg relative w-auto transition-transform duration-200 ${className}`}
    >
      <img
        src={src}
        alt={alt}
        className="h-full max-w-none object-cover rounded-lg transition-transform duration-200"
      />
    </div>
  );

  const renderInLineAddShippingAddress = () => {
    if (isLoadingUserAddress || isRefetchingUserAddress) {
      return <PaymentCardsSkeleton />;
    }
    return availableShippingAddresses.length === 0 ? (
      <AddressFormView
        countries={countries.data}
        states={states.data}
        onSubmit={handleAddShippingAddress}
        hasLeadingMessage={false}
        hasSubmitButton={false}
        hasDefaultToggle={false}
        addressFormRef={addressFormRef}
      />
    ) : (
      <Button onClick={handleShowShippingModal} type="bare">
        <AddCircleIcon className="mr-2" /> Add new address
      </Button>
    );
  };

  useEffect(() => {
    if (isReadyToCheckOrder) {
      handlePreflightCheck();
      setIsReadyToCheckOrder(false);
    }
  }, [isReadyToCheckOrder]);

  useEffect(() => {
    if (order.status === STATUS_SUCCESS) {
      setShowConfirmationModal(true);
      setShowLoadingModal(false);
      clearPublish();
    }
  }, [clearPublish, order]);

  useEffect(() => {
    if (defaultPaymentOption) {
      const currentSet = availablePaymentOptions;
      currentSet.add(defaultPaymentOption);
      setAvailablePaymentOptions(currentSet);
      setSelectedPayment(defaultPaymentOption);
    }
  }, [availablePaymentOptions, defaultPaymentOption]);

  return (
    <>
      <div className="w-1/12 flex gap-1 items-center cursor-pointer" onClick={goBack}>
        <ArrowLeft stroke='#3C3C3C'/>
        <span className='font-medium text-sm'>Back</span>
      </div>

      <Box className="mt-6">
        <div className="flex justify-between items-center border-b-[1px] p-7">
          <span className="text-lg font-semibold">Review</span>
        </div>

        <div className="p-7 flex max-[1365px]:flex-col gap-[24px]">
          <div className="w-1/2 max-[1365px]:w-full">

            <div className="justify-start max-[1250px]:flex-col">
              <div className="w-[90%] min-w-80">
                <div className="flex items-center gap-2 mb-4 text-neutral-900">
                  <CreditCardIcon /> Choose payment method
                </div>

                <div className="max-xl:overflow-x-auto relative">
                  <div className="mb-6">
                    {loadingGetPaymentProfilesList ||
                defaultProfileLoading ||
                defaultProfileRefetching ||
                refetchingGetPaymentProfilesList ? (
                        <PaymentCardsSkeleton />
                      ) : (
                        Array.from(availablePaymentOptions.toArray()).map((item, index) => {
                          const selected =
                      'border-primary-500 bg-addressSelect border-[2px] rounded-lg flex gap-4 items-center p-4 mb-2 hover:bg-primary-50 transition duration-300 ease-in-out cursor-pointer';
                          const defaultStyle =
                      'border-neutral-200 gap-4 items-center flex border-[2px] rounded-lg p-4 mb-2 hover:bg-neutral-100 transition duration-300 ease-in-out cursor-pointer';
                          return (
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                            <label
                              key={item.id}
                              className={selectedPayment?.id === item.id ? selected : defaultStyle}
                              onClick={() => setSelectedPayment(item)}
                              htmlFor={item.id}
                            >
                              <Radio
                                id={item.id}
                                name="cards"
                                value={item.id}
                                onChange={() => setSelectedPayment(item)}
                                className={classNames(
                                  selectedPayment?.id === item.id && 'border-4 border-primary-500',
                                )}
                              />
                              <img
                                src={CreditCardImages[item?.card_type]}
                                alt={item?.card_type}
                                className="w-[46px] h-[32px] rounded-sm"
                              />
                              <span className="text-sm font-semibold">
                          Card {styleMask(item)} {isDefaultPayment(item.id) && '(Default)'}
                              </span>
                            </label>
                          );
                        })
                      )}

                    <Button
                      wrapperClassName="flex items-center"
                      onClick={handleAddNewPaymentMethod}
                      type="bare"
                    >
                      <AddCircleIcon className="mr-2" /> Add new payment method
                    </Button>
                  </div>
                </div>
                <ChangeDefaultPaymentModal
                  showModal={defaultCardModal}
                  setShowModal={setDefaultCardModal}
                  isDefault
                />
              </div>
            </div>


            <div id="shipping-address-" className="justify-items-start w-full">
              <div className="flex gap-2 items-start mb-4">
                <ShippingIcon /> <span className="text-sm font-semibold">Ship to</span>
              </div>
              <ShippingAddressSelectNew
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                shippingAddressList={availableShippingAddresses}
                isRefeching={isRefetchingUserAddress}
                additionalShippingLoading={isRefetchingUserAddress || isLoadingUserAddress}
              />
              <span className="flex items-center">{renderInLineAddShippingAddress()}</span>

              <div className="bg-neutral-100 rounded-lg p-4 mb-5 mt-5">
                <span className="text-sm text-neutral-700 flex items-center">
                  <InfoCircle className="mr-2" /> Any images added to the guides will be printed in
                  black and white
                </span>
              </div>
            </div>
          </div>
          <div className="w-1/2 max-[1365px]:w-full">
            <div id="summary" className="justify-items-start bg-neutral-50 rounded-lg p-6">
              <span className="text-2xl font-bold">Summary</span>

              <div className="mt-7 gap-[14px] flex max-[510px]:flex-col max-[510px]:items-center">
                <div className="flex gap-2 items-center mb-2">
                  <GuideThumbnail
                    src={thumbnailUrl}
                    alt={guideTitle}
                    className="group-hover:scale-105 min-w-24"
                  />
                </div>

                <div>
                  <div className="flex gap-2 items-center mb-2">
                    <span className="text-base font-semibold">{selectedOrderContext?.title}</span>
                  </div>
                  <Dropdown items={productsOptions} setSelectedQtyOption={setSelectedQtyOption} setGuideQuantity={setGuideQuantity} selectedQtyOption={selectedQtyOption}  />
                </div>
              </div>

              <div className="mt-7 mb-7 flex w-full">
                <div className='justify-start w-1/2'>
                  <span className="text-sm text-neutral-400 font-medium">Shipping</span>
                </div>

                <div className="flex flex-end ml-auto justify-end">
                  <span className="text-sm text-neutral-400 font-medium">free</span>
                </div>
              </div>

              <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700"/>

              <div className="mt-7 mb-7 flex w-full">
                <div className='justify-start w-1/2'>
                  <span className="text-base text-neutral-800 font-semibold">Total due today</span>
                </div>

                <div className="flex flex-end ml-auto justify-end">
                  <span className="text-base text-neutral-800 font-semibold">{selectedQtyOption.price}</span>
                </div>
              </div>

              <div className="mt-7 mb-7 flex w-full">
                <Button
                  onClick={handleCheckoutSubmit}
                  disabled={
                    isRefetchingUserAddress
                  }
                  full
                  wrapperClassName="w-full"
                >
                  <span className='font-bold text-base'>Place order</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Box>

      <ShippingNewAddress
        openAddressForm={showShippingModal}
        setOpenAddressForm={setShowShippingModal}
        fetchUserAddressesRefetch={fetchUserAddressesRefetch}
      />

      {showAddCardModal && (
        <AddCardModal showModal={showAddCardModal} setShowModal={setShowAddCardModal} />
      )}

      <ProcessingModal
        title="Processing your order..."
        description="Please don’t close this window"
        showModal={showLoadingModal}
        setShowModal={setShowLoadingModal}
        theme={customLoadingModalTheme}
      />

      <ConfirmationModal
        title="Thank you. Your order is confirmed!"
        description="Please allow 7-10 days for us to print and ship your order."
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        onConfirmation={handleConfirmation}
        confirmationText="View order"
      />
    </>
  );
};

export default compose(withAddress, withPublish)(GuideCheckout);
